<template>
  <base-section id="what-is-syrf">
    <base-section-heading
      color="grey lighten-2"
      image="android-chrome-512x512.png"
      :title="$ml.get('what_is_syrf_header')"
    />

    <v-container>
      <v-row class="mx-auto">
        <v-col cols="12" md="10" v-if="$ml.current == 'en'">
          <p>
            <b> SYRF </b> is a token that allows you to invest in the real
            sector economy. By purchasing this token into your portfolio, you
            are an investor and will receive dividends in proportion to the number of
            coins you own. Dividends are paid at least twice a month. Here are
            some points to help you better understand what SYRF is.
          </p>
          <b> SYRF token has a limited amount. </b> <br />
          <ul>
            Limited to existing assets.
            <br />
            Additional issuance (release) of a token is made only when provided
            there is a real direction for investing data funds. (At this time,
            more than 10% of the amount of already existing tokens in
            circulation, so as not to dilute the share of already existing
            holders and not reduce their profits)
            <br />
          </ul>
          <b> All received profit from investment is divided like this: </b>
          <br />
          <ul>
            10% goes to the exchanger
            <a
              target="blank"
              href="https://waves.exchange/trading/spot/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK_USDT"
            >
              waves.exchange
            </a>
            and is put on the buyout of a token in the SYRF / USDT pair at a
            price of 0.9 USDT.
            <br />
            30% goes to reinvestment and development, to increase making a
            profit.
            <br />
            60% is distributed among the holders of the SYRF token, in
            proportion to their shares. Payments are made to the wallet where at least 1 SYRF token is stored, 
            at least two once a month in tokens SYRF, USDT, ETH, BTC, etc.
          </ul>
        </v-col>
        <v-col cols="12" md="12" v-else>
          <p>
            <b>SYRF</b> - это токен, позволяющий инвестировать в реальный сектор
            экономики. Купив данный токен в свой портфель, вы являетесь
            инвестором и будете получать дивиденды пропорционально количеству монет,
            которыми владеете. Дивиденды выплачиваются не реже двух раз в месяц.
            Вот некоторые тезисы, которые помогут вам лучше понять что же из
            себя представляет SYRF.
          </p>
          <b>Токен SYRF имеет ограниченное количество.</b><br />
          <ul>
            Количество ограничено существующими активами.<br />
            Дополнительное эмитирование (выпуск) токена производится только при
            условии, что существует реальное направление для инвестирования
            данных средств. (В данный момент времени не может быть разово
            эмитировано более 10% от суммы уже существующих токенов в обороте,
            чтобы не размывать долю уже существующих держателей и не уменьшать
            их прибыль)<br />
          </ul>
          <b>Вся полученная прибыль, от инвестирования, делится так:</b><br />
          <ul>
            10% отправляется на обменник
            <a
              target="blank"
              href="https://waves.exchange/trading/spot/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK_USDT"
              >waves.exchange</a
            >
            и ставится на откуп токена в паре SYRF/USDT по цене 0,9 USDT.<br />
            30% отправляется в повторное инвестирование и развитие, для
            увеличения получения прибыли.<br />
            60% распределяются между держателями токена SYRF, соразмерно их долям. 
            Выплаты производятся на кошельки где хранится не менее 1 токена SYRF, не реже двух раз в месяц в токенах SYRF, USDT, ETH, BTC и др.
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "WhatIsSyrf",

  data: () => ({}),
};
</script>
